import { InformationCircleIcon, RefreshIcon } from "@heroicons/react/outline";
import { useState, useEffect } from "react";
import { Alert } from "./components/alerts/Alert";
import { Grid } from "./components/grid/Grid";
import { Keyboard } from "./components/keyboard/Keyboard";
import { AboutModal } from "./components/modals/AboutModal";
import { InfoModal } from "./components/modals/InfoModal";
import { WinModal } from "./components/modals/WinModal";
import { isValidCode, isWinningCode, solution } from "./lib/codes";
import {
    restartGameStateToLocalStorage,
    loadGameStateFromLocalStorage,
    saveGameStateToLocalStorage
} from "./lib/localStorage";

function App() {
    const [currentGuess, setCurrentGuess] = useState("");
    const [isGameWon, setIsGameWon] = useState(false);
    const [isWinModalOpen, setIsWinModalOpen] = useState(false);
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
    const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);
    const [isCodeNotFoundAlertOpen, setIsCodeNotFoundAlertOpen] = useState(false);
    const [isGameLost, setIsGameLost] = useState(false);
    const [shareComplete, setShareComplete] = useState(false);
    const [guesses, setGuesses] = useState<string[]>(() => {
        const loaded = loadGameStateFromLocalStorage();
        if (loaded?.solution !== solution) {
            return [];
        }
        if (loaded.guesses.includes(solution)) {
            setIsGameWon(true);
        }
        return loaded.guesses;
    });

    useEffect(() => {
        saveGameStateToLocalStorage({ guesses, solution });
    }, [guesses]);

    useEffect(() => {
        if (isGameWon) {
            setIsWinModalOpen(true);
        }
    }, [isGameWon]);

    const onChar = (value: string) => {
        if (currentGuess.length < 5 && guesses.length < 6) {
            setCurrentGuess(`${currentGuess}${value}`);
        }
    };

    const onDelete = () => {
        setCurrentGuess(currentGuess.slice(0, -1));
    };

    const onEnter = () => {
        if (!isValidCode(currentGuess)) {
            setIsCodeNotFoundAlertOpen(true);
            return setTimeout(() => {
                setIsCodeNotFoundAlertOpen(false);
            }, 2000);
        }

        const winningCode = isWinningCode(currentGuess);

        if (currentGuess.length === 5 && guesses.length < 6 && !isGameWon) {
            setGuesses([...guesses, currentGuess]);
            setCurrentGuess("");

            if (winningCode) {
                return setIsGameWon(true);
            }

            if (guesses.length === 5) {
                setIsGameLost(true);
                return setTimeout(() => {
                    setIsGameLost(false);
                }, 2000);
            }
        }
    };

    return (
        <div className="py-8 max-w-7xl mx-auto sm:px-6 lg:px-8">
            <Alert message="Not a 5 digit" isOpen={isCodeNotFoundAlertOpen} />
            <Alert message={`You lost, the word was ${solution}`} isOpen={isGameLost} />
            <Alert message="Game copied to clipboard" isOpen={shareComplete} variant="success" />
            <div className="flex w-80 mx-auto items-center mb-8">
                <h1 className="text-xl grow font-bold">Combination lock game</h1>
                <RefreshIcon
                    className="h-6 w-6 cursor-pointer"
                    onClick={() => restartGameStateToLocalStorage()}
                />
                <InformationCircleIcon
                    className="h-6 w-6 cursor-pointer"
                    onClick={() => setIsInfoModalOpen(true)}
                />
            </div>
            <Grid guesses={guesses} currentGuess={currentGuess} />
            <Keyboard onChar={onChar} onDelete={onDelete} onEnter={onEnter} guesses={guesses} />
            <WinModal
                isOpen={isWinModalOpen}
                handleClose={() => setIsWinModalOpen(false)}
                guesses={guesses}
                handleShare={() => {
                    setIsWinModalOpen(false);
                    setShareComplete(true);
                    return setTimeout(() => {
                        setShareComplete(false);
                    }, 2000);
                }}
            />
            <InfoModal isOpen={isInfoModalOpen} handleClose={() => setIsInfoModalOpen(false)} />
            <AboutModal isOpen={isAboutModalOpen} handleClose={() => setIsAboutModalOpen(false)} />

            <button
                type="button"
                className="mx-auto mt-8 flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => setIsAboutModalOpen(true)}
            >
                About this game
            </button>
        </div>
    );
}

export default App;
